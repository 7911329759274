import * as http from "./http"

import { Result, success, failure } from "../types/result"

import * as CheckoutTypes from "../types/checkout"
import * as Order from "../types/order"
import Profile from "../types/profile"
import * as Address from "../types/address"
import * as CreditCard from "../types/credit-card"
import * as Fulfilment from "../types/fulfilment"
import * as Payment from "../types/payment"
import * as User from "../types/user"
import * as Session from "../types/session"

import getCsrfToken from "../utils/get-csrf-token"

const OK = 200
const CREATED = 201
const ACCEPTED = 202
const BAD_REQUEST = 400
const NOT_FOUND = 404
const UNAUTHORIZED = 401
const UNPROCESSABLE_ENTITY = 422

const API_ROOT = process.env.GATSBY_VINOMOFO_API_URL

const validateEmail = async (
  email: string
): Promise<Result<Session.EmailValidationResult, undefined>> => {
  const path = new URL("/api/v2/validate-email", API_ROOT)
  const payload = { email }
  const headers = { "X-CSRF-TOKEN": getCsrfToken() }
  const response = await http._post(path.toString(), payload, headers)
  const result = await response.json()

  switch (response.status) {
    case OK:
      return success(result)
    case BAD_REQUEST:
      return failure(undefined)
    default:
      throw response
  }
}

const createSession = async (
  email: string,
  password: string
): Promise<Result<
  { message: string },
  {
    error: string
    errors?: { [key: string]: string }
  }
>> => {
  const path = new URL("/api/v2/sessions/email", API_ROOT)
  const payload = { session: { email, password } }
  const response = await http._post(path.toString(), payload)
  const result = await response.json()

  switch (response.status) {
    case ACCEPTED:
      return success(result)
    case UNAUTHORIZED:
      return failure(result)
    default:
      throw response
  }
}

const createFacebookSession = async (
  accessToken: string
): Promise<Result<
  { message: string },
  {
    error: string
    errors?: { [key: string]: string }
  }
>> => {
  const path = new URL("/api/v2/sessions/facebook", API_ROOT)
  const payload = { session: { access_token: accessToken } }
  const response = await http._post(path.toString(), payload)
  const result = await response.json()

  switch (response.status) {
    case ACCEPTED:
      return success(result)
    case UNAUTHORIZED:
      return failure(result)
    default:
      throw response
  }
}

const assignGuestUser = async (
  user: User.Guest
): Promise<Result<
  { message: string; checkout: CheckoutTypes.Checkout },
  {
    error: string
    errors?: { [key: string]: string }
  }
>> => {
  const path = new URL("/api/v2/checkout/guest", API_ROOT)
  const payload = { guest: { email: user.profile.email } }
  const response = await http._post(path.toString(), payload)
  const result = await response.json()

  switch (response.status) {
    case ACCEPTED:
      return success(result)
    case UNPROCESSABLE_ENTITY:
      return failure(result)
    default:
      throw response
  }
}

const resetPassword = async (
  email: string
): Promise<Result<
  { message: string },
  {
    error: string
  }
>> => {
  const path = new URL("/api/v2/reset-password", API_ROOT)
  const payload = { session: { email } }
  const response = await http._post(path.toString(), payload)
  const result = await response.json()

  switch (response.status) {
    case ACCEPTED:
      return success(result)
    case BAD_REQUEST:
      return failure(result)
    default:
      throw response
  }
}

const registerUser = async (
  registration: User.Registration
): Promise<Result<{ message: string }, User.RegistrationErrors>> => {
  const path = new URL("/api/v2/registrations", API_ROOT)
  const payload = { registration }
  const response = await http._post(path.toString(), payload)
  const result = await response.json()

  switch (response.status) {
    case CREATED:
      return success(result)
    case UNPROCESSABLE_ENTITY:
      return failure(result)
    default:
      throw response
  }
}

const getCurrentProfile = async (): Promise<Result<
  { uid: number | string; profile: Profile },
  { error: string }
>> => {
  const path = new URL("/api/v2/me", API_ROOT)
  const response = await http._get(path.toString())
  const result = await response.json()

  switch (response.status) {
    case OK:
      return success(result)
    case UNAUTHORIZED:
      return failure(result)
    default:
      throw response
  }
}

const destroySession = async (): Promise<Result<
  { message: string; redirect_to: string },
  never
>> => {
  const path = new URL("/api/v2/sessions", API_ROOT)
  const response = await http._delete(path.toString())
  const result = await response.json()

  switch (response.status) {
    case OK:
      return success(result)
    default:
      throw response
  }
}

const getCheckout = async (): Promise<Result<
  { message?: string; checkout: CheckoutTypes.Checkout },
  never
>> => {
  const path = new URL("/api/v2/checkout", API_ROOT)
  const response = await http._get(path.toString())
  const result = await response.json()

  switch (response.status) {
    case OK:
      return success(result)
    default:
      throw response
  }
}

const getStoredAddresses = async (): Promise<Result<
  { addresses: Array<Address.Persisted> },
  { error: string }
>> => {
  const path = new URL("/api/v2/addresses", API_ROOT)
  const response = await http._get(path.toString())
  const result = await response.json()

  switch (response.status) {
    case OK:
      return success(result)
    case UNAUTHORIZED:
      return failure(result)
    default:
      throw response
  }
}

const getStoredCreditCards = async (): Promise<Result<
  { credit_cards: Array<CreditCard.Persisted> },
  { error: string }
>> => {
  const path = new URL("/api/v2/credit-cards", API_ROOT)
  const response = await http._get(path.toString())
  const result = await response.json()

  switch (response.status) {
    case OK:
      return success(result)
    case UNAUTHORIZED:
      return failure(result)
    default:
      throw response
  }
}

const updateFulfilment = async (
  fulfilment: Fulfilment.Any
): Promise<Result<
  { message: string; checkout: CheckoutTypes.Checkout },
  Fulfilment.Errors
>> => {
  const path = new URL("/api/v2/checkout/fulfilment", API_ROOT)
  const response = await http._patch(path.toString(), { fulfilment })
  const result = await response.json()

  switch (response.status) {
    case OK:
      return success(result)
    case UNPROCESSABLE_ENTITY:
      return failure(result)
    default:
      throw response
  }
}

const updatePayment = async (
  payment: Payment.Any
): Promise<Result<
  { message: string; checkout: CheckoutTypes.Checkout },
  Payment.Errors
>> => {
  const path = new URL("/api/v2/checkout/payment", API_ROOT)
  const response = await http._patch(path.toString(), { payment })
  const result = await response.json()

  switch (response.status) {
    case OK:
      return success(result)
    case UNPROCESSABLE_ENTITY:
      return failure(result)
    default:
      throw response
  }
}

const submitOrder = async (
  purchaser_dob: Date
): Promise<Result<Order.SubmissionResult, Order.SubmissionErrors>> => {
  const path = new URL("/api/v2/checkout/submit", API_ROOT)
  const response = await http._post(path.toString(), { purchaser_dob })
  const result = await response.json()

  switch (response.status) {
    case OK:
      return success(result)
    case BAD_REQUEST:
      return failure(result)
    default:
      throw response
  }
}

const getOrder = async (
  uuid: string
): Promise<Result<{ order: Order.Submitted }, { error: string }>> => {
  const path = new URL(`/api/v2/orders/${uuid}`, API_ROOT)
  const response = await http._get(path.toString())
  const result = await response.json()

  switch (response.status) {
    case OK:
      return success(result)
    case NOT_FOUND:
      return failure(result)
    default:
      throw response
  }
}

export {
  validateEmail,
  createSession,
  createFacebookSession,
  assignGuestUser,
  registerUser,
  destroySession,
  resetPassword,
  getCurrentProfile,
  getStoredAddresses,
  getStoredCreditCards,
  getCheckout,
  updateFulfilment,
  updatePayment,
  submitOrder,
  getOrder,
}
