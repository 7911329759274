import * as FulfilmentPayload from "./fulfilment"
import * as Address from "./address"
import * as Payment from "./payment"
import * as Order from "./order"
import Currency from "./currency"
import { Result } from "./result"

type LineItem = Order.LineItem

type Checkout = {
  uid: string

  line_items: Array<LineItem>
  fulfilment: Fulfilment

  currency: Currency
  items_subtotal: number
  items_discount_total: number
  items_total: number

  shipping_subtotal: number
  shipping_discount_total: number
  shipping_total: number

  subtotal: number

  coupons: Array<{ code: string; description: string }>

  discounts_total: number
  total: number
  balance: number

  first_purchase: boolean
  guest_checkout: boolean
}

type Fulfilment = {
  shipping_address: Address.Any
  available_shipping_options: Array<FulfilmentPayload.Options>
  selected_shipping_option: FulfilmentPayload.Options
}

interface Context extends Checkout {
  loading: boolean
  updateFulfilment: (
    fulfilment: FulfilmentPayload.Any
  ) => Promise<Result<unknown, FulfilmentPayload.Errors>>
  updatePayment: (
    payment: Payment.Any
  ) => Promise<Result<unknown, Payment.Errors>>
  submitOrder: (
    purchaser_dob: Date
  ) => Promise<Result<unknown, Order.SubmissionErrors>>
}

type Interstitial = undefined

const isInterstitial = (
  checkout: Checkout | Interstitial
): checkout is Interstitial => undefined === checkout

export { Checkout, LineItem, Context, Interstitial, isInterstitial }
