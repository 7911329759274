import fetch from "isomorphic-fetch"

const _get = (path: string, headers?: { [key: string]: string }) => {
  const options: RequestInit = {
    method: "GET",
    credentials: "include",
    headers: {
      accepts: "application/json",
      ...headers,
    },
  }

  return fetch(path, options)
}

const _post = (
  path: string,
  body?: { [key: string]: any },
  headers?: { [key: string]: string }
) => {
  const options: RequestInit = {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      accepts: "application/json",
      ...headers,
    },
    body: JSON.stringify(body),
  }

  return fetch(path, options)
}

const _patch = (
  path: string,
  body?: { [key: string]: any },
  headers?: { [key: string]: string }
) => {
  const options: RequestInit = {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      accepts: "application/json",
      ...headers,
    },
    body: JSON.stringify(body),
  }

  return fetch(path, options)
}

const _put = (
  path: string,
  body?: { [key: string]: any },
  headers?: { [key: string]: string }
) => {
  const options: RequestInit = {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      accepts: "application/json",
      ...headers,
    },
    body: JSON.stringify(body),
  }

  return fetch(path, options)
}

const _delete = (path: string, headers?: { [key: string]: string }) => {
  const options: RequestInit = {
    method: "DELETE",
    credentials: "include",
    headers: {
      accepts: "application/json",
      ...headers,
    },
  }

  return fetch(path, options)
}

export { _get, _post, _patch, _put, _delete }
