import ResizeObserver from "resize-observer-polyfill"

import isBrowser from "./is-browser"

const observeSize = (element, callback) => {
  if (!isBrowser) return () => {}

  const observer = new ResizeObserver(entries => {
    window.requestAnimationFrame(() => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect
        callback({ height, width })
      }
    })
  })

  observer.observe(element)

  return () => observer.unobserve(element)
}

export default observeSize
