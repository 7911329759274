import ApolloClient from "apollo-boost"
import fetch from "isomorphic-fetch"

const client = new ApolloClient({
  uri: process.env.GATSBY_CONTENTFUL_URL,
  headers: {
    authorization: `Bearer ${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}`,
    "Content-Language": "en-us",
  },
  fetch,
})

export default client
