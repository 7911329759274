import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useMemo,
} from "react"

import * as Session from "../types/session"
import * as User from "../types/user"
import { Result, valueOrThrow } from "../types/result"

// import * as Analytics from "../utils/event-tracking"

import * as Api from "../utils/checkout-api"
import { useFlashContext } from "./flash-context"

type SessionContext = Session.Any | Session.Interstitial
const INTERSTITIAL_SESSION: Session.Interstitial = { loading: true }

type Event<T, D> = {
  action: T
  data: D
}

type SignedIn = Event<
  "SIGNED_IN",
  Result<{ message: string; source: string }, { error: string }>
>

type Registered = Event<
  "REGISTERED",
  Result<{ message: string; source: string }, { error: string }>
>

type SignedOut = Event<
  "SIGNED_OUT",
  Result<{ message: string; redirect_to: string }, { error: string }>
>

type ResetSession = Event<"RESET_SESSION", unknown>

type ContinuedAsGuest = Event<
  "CONTINUED_AS_GUEST",
  Result<{ message: string; user: User.Guest }, { error: string }>
>

type PasswordReset = Event<
  "PASSWORD_RESET",
  Result<{ message: string }, { error: string }>
>

type EventHandler = (
  event:
    | SignedIn
    | Registered
    | SignedOut
    | ResetSession
    | ContinuedAsGuest
    | PasswordReset
) => void

const event = <T, D>(action: T, data: D): Event<T, D> => ({
  action,
  data,
})

const SignedIn = (
  data: Result<{ message: string; source: string }, { error: string }>
): SignedIn => event("SIGNED_IN", data)

const Registered = (
  data: Result<{ message: string; source: string }, { error: string }>
): Registered => event("REGISTERED", data)

const Unauthenticated = (
  data: Result<{ message: string; redirect_to: string }, { error: string }>
): SignedOut => event("SIGNED_OUT", data)

const ResetSession = (): ResetSession => event("RESET_SESSION", {})

const ContinuedAsGuest = (
  data: Result<{ message: string; user: User.Guest }, { error: string }>
): ContinuedAsGuest => event("CONTINUED_AS_GUEST", data)

const PasswordReset = (
  data: Result<{ message: string }, { error: string }>
): PasswordReset => event("PASSWORD_RESET", data)

const buildUnauthenticatedSession = (
  callback: EventHandler
): Session.Unauthenticated => {
  return {
    currentUser: null,
    authenticateWithCredentials: (email, password) => {
      return Api.createSession(email, password).then(r => {
        const result = r.map(({ message }) => ({ message, source: "email" }))
        const event = SignedIn(result)
        callback(event)

        return result
      })
    },
    continueAsGuest: email => {
      const user: User.Guest = { type: "guest", profile: { email } }

      return Api.assignGuestUser(user).then(r => {
        const result = r.map(({ message }) => ({ user, message }))
        const event = ContinuedAsGuest(result)
        callback(event)

        return result
      })
    },
    authenticateWithFacebook: accessToken => {
      return Api.createFacebookSession(accessToken).then(r => {
        const result = r.map(({ message }) => ({
          message,
          source: "facebook",
        }))
        const event = SignedIn(result)
        callback(event)

        return result
      })
    },
    registerNewUser: user => {
      return Api.registerUser(user).then(r => {
        const result = r.map(({ message }) => ({ message, source: "email" }))
        const event = Registered(result)
        callback(event)

        return result
      })
    },
    resetPassword: email => {
      return Api.resetPassword(email).then(result => {
        const event = PasswordReset(result)
        callback(event)

        return result
      })
    },
    validateEmail: email => Api.validateEmail(email),
  }
}

const buildGuestSession = (
  user: User.Guest,
  callback: EventHandler
): Session.Guest => {
  return {
    currentUser: user,
    reset: () => {
      const event = ResetSession()
      callback(event)
    },
  }
}

const buildAuthenticatedSession = (
  user: User.Member,
  callback: EventHandler
): Session.Authenticated => {
  return {
    currentUser: user,
    signOut: () => {
      return Api.destroySession().then(result => {
        const event = Unauthenticated(result)
        callback(event)

        return result
      })
    },
  }
}

const buildSession = (
  currentUser: undefined | User.Any,
  callback: EventHandler
) => {
  if (undefined === currentUser) return buildUnauthenticatedSession(callback)
  if (User.isGuest(currentUser)) return buildGuestSession(currentUser, callback)
  return buildAuthenticatedSession(currentUser, callback)
}

const context = createContext<SessionContext>(INTERSTITIAL_SESSION)
const useSession = () => useContext(context)

const SessionContextWrapper = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState(false)
  const [shouldLoadCurrentUser, setShouldLoadCurrentUser] = useState(true)
  const [currentUser, setCurrentUser] = useState<User.Any>()
  const [signinSource, setSigninSource] = useState<string>()
  const [registrationSource, setRegistrationSource] = useState<string>()

  const { addFlash } = useFlashContext()

  useEffect(() => {
    if (loading || !shouldLoadCurrentUser) return

    setLoading(true)
    setShouldLoadCurrentUser(false)

    Api.getCurrentProfile()
      .then(result => {
        const { uid, profile } = valueOrThrow(result)

        return Promise.all([
          uid,
          profile,
          Api.getStoredAddresses()
            .then(a => a.map(({ addresses }) => addresses))
            .then(a => a.recover(() => []) /* defaults for failure result */)
            .then(a => valueOrThrow(a) /* unwrap the value object */),
          Api.getStoredCreditCards()
            .then(c => c.map(({ credit_cards }) => credit_cards))
            .then(c => c.recover(() => []) /* defaults on failure result */)
            .then(c => valueOrThrow(c) /* unwrap the value object */),
        ])
      })
      .then(([uid, profile, addresses, credit_cards]) => {
        setCurrentUser({
          type: "member",
          uid,
          profile,
          addresses,
          credit_cards,
        })

        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        setCurrentUser(undefined)
        console.error(e)
      })
  }, [loading, shouldLoadCurrentUser])

  const session = useMemo(() => {
    if (loading || shouldLoadCurrentUser) return INTERSTITIAL_SESSION

    const callback: EventHandler = event => {
      switch (event.action) {
        case "PASSWORD_RESET":
          event.data
            .withValue(({ message }) => addFlash({ success: message }))
            .withError(({ error }) => addFlash({ error }))
          return
        case "SIGNED_IN":
          event.data
            .withValue(({ message, source }) => {
              setSigninSource(source)
              setShouldLoadCurrentUser(true)
              addFlash({ success: message })
            })
            .withError(({ error }) => addFlash({ error }))
          return
        case "REGISTERED":
          event.data
            .withValue(({ message, source }) => {
              setRegistrationSource(source)
              setShouldLoadCurrentUser(true)
              addFlash({ success: message })
            })
            .withError(({ error }) => addFlash({ error }))
          return
        case "CONTINUED_AS_GUEST":
          event.data
            .withValue(({ user }) => setCurrentUser(user))
            .withError(({ error }) => addFlash({ error }))
          return
        case "SIGNED_OUT":
          event.data
            .withValue(({ redirect_to }) => {
              // Analytics.userSessionEnded()
              window.location.href = redirect_to
            })
            .withError(({ error }) => addFlash({ error }))
          return
        case "RESET_SESSION":
          setCurrentUser(undefined)
          return
      }
    }

    return buildSession(currentUser, callback)
  }, [loading, currentUser])

  useEffect(() => {
    if (undefined === currentUser) return
    // Analytics.identifyUser(currentUser)
  }, [currentUser])

  useEffect(() => {
    if (undefined === currentUser || undefined === signinSource) return
    // Analytics.userSessionStarted(signinSource, currentUser.profile.email)
    setSigninSource(undefined)
  }, [currentUser, signinSource])

  useEffect(() => {
    if (undefined === currentUser || undefined === registrationSource) return
    // Analytics.userRegistered(registrationSource, currentUser.profile.email)
    setRegistrationSource(undefined)
  }, [currentUser, registrationSource])

  return <context.Provider value={session}>{children}</context.Provider>
}

export { useSession, SessionContextWrapper }
