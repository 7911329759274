import React from "react"
import { ApolloProvider } from "react-apollo"
import contentfulClient from "./src/utils/contentful-client"

import { MarketContextWrapper } from "lib-vinomofo/contexts/market-context"
import { SessionContextWrapper } from "lib-vinomofo/contexts/session-context"
import { CheckoutContextWrapper } from "lib-vinomofo/contexts/checkout-context"
import { SearchModalWrapper } from "lib-vinomofo/contexts/search-modal-context"
import LoadingOverlay from "lib-vinomofo/components/loading-overlay"

import "lib-vinomofo/styles/lib/reset.scss"
import "lib-vinomofo/styles/fonts.scss"
import "lib-vinomofo/styles/global.scss"

export const wrapRootElement = ({ element }) => {
  return (
    <LoadingOverlay fadeOut>
      <MarketContextWrapper>
        <SessionContextWrapper>
          <CheckoutContextWrapper>
            <SearchModalWrapper>
              <ApolloProvider client={contentfulClient}>{element}</ApolloProvider>
            </SearchModalWrapper>
          </CheckoutContextWrapper>
        </SessionContextWrapper>
      </MarketContextWrapper>
    </LoadingOverlay>
  )
}
