import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import {
  useWidthBreakpoints,
  Breakpoint,
} from "lib-vinomofo/hooks/use-breakpoints"
import classNames from "lib-vinomofo/utils/class-names"
import AlignmentWrapper from "lib-vinomofo/components/alignment-wrapper"
import { useSearchModal } from "lib-vinomofo/contexts/search-modal-context"

import SearchInput from "./search-input"
import styles from "./search-modal.module.scss"

const breakpoints = [
  Breakpoint("medium", [768, 1199]),
  Breakpoint("large", [1200, null]),
]

const CloseButton = ({ onClick }) => {
  return (
    <button className={styles.closeButton} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M403.1 108.9c-81.2-81.2-212.9-81.2-294.2 0s-81.2 212.9 0 294.2c81.2 81.2 212.9 81.2 294.2 0s81.2-213 0-294.2zm-12.3 281.9c-74.3 74.3-195.3 74.3-269.6 0-74.3-74.3-74.3-195.3 0-269.6s195.3-74.3 269.6 0c74.4 74.3 74.4 195.3 0 269.6z" />
        <path d="M340.2 160l-84.4 84.2-84-83.8-11.8 11.8 84 83.8-84 83.8 11.8 11.8 84-83.8 84.4 84.2 11.8-11.8-84.4-84.2 84.4-84.2z" />
      </svg>
    </button>
  )
}

const Modal = ({ onClose, children }) => {
  useEffect(() => {
    const handleKeyDown = ({ key }) => {
      key === "Escape" && onClose()
    }

    window.addEventListener("keydown", handleKeyDown)
    return () => window.removeEventListener("keydown", handleKeyDown)
  }, [onClose])

  return (
    <div className={styles.modal}>
      <div className={styles.contentWrapper}>
        <CloseButton onClick={onClose} />
        <AlignmentWrapper verticalAlignment="middle">
          <div className={styles.content}>{children}</div>
        </AlignmentWrapper>
      </div>
    </div>
  )
}

const QUERY_PARAM = "q"

const SearchModal = ({ onSearch }) => {
  const [size, breakpointRef] = useWidthBreakpoints(breakpoints)
  const classes = classNames([styles.searchModal, styles[size]])

  const searchModal = useSearchModal()
  const hideSearchModal = () => searchModal.toggleVisibility(false)

  const queryParams = new URLSearchParams(window.location.search)
  const [query, setQuery] = useState(queryParams.get(QUERY_PARAM) || "")
  const handleSearch = evt => {
    evt.preventDefault()
    onSearch(query)
  }

  return (
    <div className={classes} ref={breakpointRef}>
      <Modal onClose={hideSearchModal}>
        <h2 className={styles.title}>What can we get for you?</h2>
        <form onSubmit={handleSearch}>
          <SearchInput
            value={query}
            name={QUERY_PARAM}
            onChange={setQuery}
            autoFocus
          />

          <AlignmentWrapper horizontalAlignment="right">
            <button className={styles.submit} type="submit">
              Press ENTER to search
              <span className={styles.closeInstructions}> or ESC to close</span>
            </button>
          </AlignmentWrapper>
        </form>
      </Modal>
    </div>
  )
}

SearchModal.propTypes = {
  onSearch: PropTypes.func.isRequired,
}

export default SearchModal
