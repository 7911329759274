import React, { useEffect, useState, Fragment } from "react"

import classNames from "../../utils/class-names"

import styles from "./loading-overlay.module.scss"

const LoadingOverlay = ({ fadeOut, children }) => {
  const [isFading, setFading] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const classes = classNames([
    styles.loadingOverlay,
    isFading && styles.fadeOut,
  ])

  useEffect(() => {
    if (fadeOut) {
      setFading(true)
      const timeoutFn = setTimeout(() => {
        setLoading(false)
      }, 200)

      return () => clearTimeout(timeoutFn)
    }
  }, [fadeOut])

  return (
    <Fragment>
      {isLoading && (
        <div className={classes}>
          <div className={styles.header} />
        </div>
      )}
      {children}
    </Fragment>
  )
}

export default LoadingOverlay
