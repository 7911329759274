import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react"

import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock"

import { useMarketContext } from "../market-context"
import SearchModal from "../../components/search-modal"
import redirectTo from "../../utils/redirect-to"

const searchModalContext = createContext({
  isVisible: false,
  toggleVisibility: () => {},
})

export const useSearchModal = () => {
  return useContext(searchModalContext)
}

export const SearchModalWrapper = ({ children }) => {
  const [isVisible, setVisible] = useState(false)
  const toggleVisibility = (visiblilty = !isVisible) => setVisible(visiblilty)

  const { href } = useMarketContext()
  const onSearch = query => redirectTo(`${href}/wines?query=${query}`)

  const scrollRef = useRef()

  useEffect(() => {
    const node = scrollRef.current
    isVisible ? disableBodyScroll(node) : enableBodyScroll(node)
    return () => enableBodyScroll(node)
  }, [isVisible, scrollRef])

  return (
    <searchModalContext.Provider value={{ isVisible, toggleVisibility }}>
      {children}
      <div ref={scrollRef}>
        {isVisible && <SearchModal onSearch={onSearch} />}
      </div>
    </searchModalContext.Provider>
  )
}
