import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react"

import { navigate } from "@reach/router"

import * as Analytics from "../utils/event-tracking"

import * as CheckoutTypes from "../types/checkout"
import * as Session from "../types/session"

import { useSession } from "./session-context"
import { useFlashContext } from "./flash-context"
import * as Api from "../utils/checkout-api"

const INTERSTITIAL_CHECKOUT: CheckoutTypes.Interstitial = undefined

const context = createContext<
  CheckoutTypes.Context | CheckoutTypes.Interstitial
>(INTERSTITIAL_CHECKOUT)

const useCheckout = () => useContext(context)

const CheckoutContextWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const session = useSession()
  const [state, setState] = useState<CheckoutTypes.Checkout>()
  const [loading, setLoading] = useState(false)
  const { addFlash } = useFlashContext()

  const fetchCheckout = () => {
    return Api.getCheckout().then(result => {
      result.withValue(({ message, checkout }) => {
        if (undefined !== message) {
          addFlash({ info: message })
        }
        setState(checkout)
      })
    })
  }

  useEffect(() => {
    if (Session.isInterstitial(session)) {
      setState(INTERSTITIAL_CHECKOUT)
      return
    }

    setLoading(true)
    fetchCheckout().then(() => {
      setLoading(false)
    })
  }, [session])

  const checkout = useMemo<
    CheckoutTypes.Context | CheckoutTypes.Interstitial
  >(() => {
    if (CheckoutTypes.isInterstitial(state)) return INTERSTITIAL_CHECKOUT

    return {
      ...state,
      loading,
      updateFulfilment: fulfilment => {
        setLoading(true)

        return Api.updateFulfilment(fulfilment).then(result => {
          return fetchCheckout().then(() => {
            return result
              .withValue(() => {
                // Analytics.trackCheckoutStepCompleted(state, "shipping", 2)
                setLoading(false)
              })
              .withError(({ error }) => {
                addFlash({ error })
                setLoading(false)
              })
          })
        })
      },
      updatePayment: payment => {
        setLoading(true)

        return Api.updatePayment(payment).then(result => {
          return fetchCheckout().then(() => {
            return result
              .withValue(() => {
                // Analytics.trackCheckoutStepCompleted(state, "payment", 3)
                setLoading(false)
              })
              .withError(({ error }) => {
                addFlash({ error })
                setLoading(false)
              })
          })
        })
      },
      submitOrder: purchaser_dob => {
        setLoading(true)

        return Api.submitOrder(purchaser_dob).then(result => {
          return result
            .withValue(({ message, redirect_to }) => {
              addFlash({ success: message })
              navigate(redirect_to, { replace: true })
            })
            .withError(({ error }) => {
              addFlash({ error })
              setLoading(false)
            })
        })
      },
    }
  }, [loading, state])

  return <context.Provider value={checkout}>{children}</context.Provider>
}

export { useCheckout, CheckoutContextWrapper }
