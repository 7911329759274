import Profile from "./profile"
import { Persisted as Address } from "./address"
import { Persisted as CreditCard } from "./credit-card"

type Registration = {
  email: string
  password: string
  name: string
  birthdate?: Date
  marketing_optout?: boolean
}

type RegistrationErrors = {
  error: string
  errors?: {
    email?: string
    password?: string
    name?: string
    birthdate?: string
  }
}

type Member = {
  type: "member"
  uid: any
  profile: Profile
  addresses: Address[]
  credit_cards: CreditCard[]
}

type Guest = {
  type: "guest"
  profile: {
    email: string
    birthdate?: Date
  }
}

type Any = Member | Guest

const isMember = (user: Any): user is Member => {
  return "member" === user.type
}

const isGuest = (user: Any): user is Guest => {
  return "guest" === user.type
}

export {
  Registration,
  RegistrationErrors,
  Any,
  Member,
  Guest,
  isMember,
  isGuest,
}
