import React, { createContext, useContext, useState } from "react"
import { ulid } from "ulid"

const TIMEOUT = 10000

type Message = {
  uid: string
  expired?: boolean
}

type WarningMessage = Message & {
  warning: string
}

type InfoMessage = Message & {
  info: string
}

type SuccessMessage = Message & {
  success: string
}

type ErrorMessage = Message & {
  error: string
}

type Any = WarningMessage | InfoMessage | SuccessMessage | ErrorMessage

const isWarning = (message: Any): message is WarningMessage => {
  return !!(message as WarningMessage).warning
}

const isInfo = (message: Any): message is InfoMessage => {
  return !!(message as InfoMessage).info
}

const isSuccess = (message: Any): message is SuccessMessage => {
  return !!(message as SuccessMessage).success
}

const isError = (message: Any): message is ErrorMessage => {
  return !!(message as ErrorMessage).error
}

const context = createContext<{
  flashes: Any[]
  addFlash: (
    message:
      | { warning: string }
      | { info: string }
      | { success: string }
      | { error: string }
  ) => void
}>({ flashes: [], addFlash: () => {} })

const useFlashContext = () => useContext(context)

const FlashContextWrapper = ({ children }: { children: React.ReactNode }) => {
  const [flashes, setFlashes] = useState<Any[]>([])

  const addFlash = (message: Any) => {
    const flash = { ...message, uid: ulid() }
    setFlashes(flashes => flashes.concat([flash]))

    const remove = () =>
      setFlashes(flashes => flashes.filter(f => f.uid !== flash.uid))

    const expire = () => {
      setFlashes(flashes =>
        flashes.map(f => {
          if (f.uid !== flash.uid) return f
          return { ...f, expired: true }
        })
      )

      setTimeout(remove, 300)
    }

    setTimeout(expire, TIMEOUT)
  }

  return (
    <context.Provider value={{ flashes, addFlash }}>
      {children}
    </context.Provider>
  )
}

export {
  useFlashContext,
  FlashContextWrapper,
  isWarning,
  isInfo,
  isSuccess,
  isError,
}
