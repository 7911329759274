import { useRef, useState, useLayoutEffect } from "react"

import observeSize from "../utils/observe-size"

const createBreakpoint = (name, range) => {
  const test = size => {
    const [start, end] = range

    const afterStart = null === start || size >= start
    const beforeEnd = null === end || size <= end

    return afterStart && beforeEnd
  }

  return { test, name }
}

const fallback = createBreakpoint(null, [null, null])

const useWidthBreakpoints = (breakpoints, breakpointRef) => {
  const defaultRef = useRef()
  const ref = breakpointRef || defaultRef
  const [currentBreakpoint, setCurrentBreakpoint] = useState(fallback.name)

  useLayoutEffect(() => {
    if (!!ref.current) {
      return observeSize(ref.current, size => {
        const found = [...breakpoints, fallback].find(b => {
          return b.test(size.width)
        })

        setCurrentBreakpoint(found.name)
      })
    }
  }, [ref, breakpoints])

  return [currentBreakpoint, ref]
}

export { useWidthBreakpoints, createBreakpoint as Breakpoint }
