interface Result<V, E> {
  readonly value?: V
  readonly error?: E

  map<U>(f: (value: V) => U): Result<U, E>
  flatMap<U>(f: (value: V) => Result<U, E>): Result<U, E>

  mapError<U>(f: (error: E) => U): Result<V, U>
  flatMapError<U>(f: (error: E) => Result<V, U>): Result<V, U>

  recover(f: (error: E) => V): Result<V, E>

  withValue(f: (value: V) => void): Result<V, E>
  withError(f: (error: E) => void): Result<V, E>
}

class Success<V, E = unknown> implements Result<V, E> {
  constructor(private v: V) {}

  get value(): V | undefined {
    return this.v
  }

  get error(): E | undefined {
    return undefined
  }

  map<U>(f: (value: V) => U): Result<U, E> {
    const value = this.v
    return success(f(value))
  }

  flatMap<U>(f: (value: V) => Result<U, E>): Result<U, E> {
    return f(this.v)
  }

  mapError<U>(f: (error: E) => U): Result<V, U> {
    return success(this.v)
  }

  flatMapError<U>(f: (error: E) => Result<V, U>): Result<V, U> {
    return success(this.v)
  }

  recover(f: (error: E) => V): Result<V, E> {
    return success(this.v)
  }

  withValue(f: (value: V) => void): Result<V, E> {
    f(this.v)
    return this
  }

  withError(f: (error: E) => void): Result<V, E> {
    return this
  }
}

class Failure<E, V = unknown> implements Result<V, E> {
  constructor(private e: E) {}

  get value(): V | undefined {
    return undefined
  }

  get error(): E | undefined {
    return this.e
  }

  map<U>(f: (value: V) => U): Result<U, E> {
    return failure(this.e)
  }

  flatMap<U>(f: (value: V) => Result<U, E>): Result<U, E> {
    return failure(this.e)
  }

  mapError<U>(f: (error: E) => U): Result<V, U> {
    return failure(f(this.e))
  }

  flatMapError<U>(f: (error: E) => Result<V, U>): Result<V, U> {
    return f(this.e)
  }

  recover(f: (error: E) => V): Result<V, E> {
    return success(f(this.e))
  }

  withValue(f: (value: V) => void): Result<V, E> {
    return this
  }

  withError(f: (error: E) => void): Result<V, E> {
    f(this.e)
    return this
  }
}

class InternalInconsistencyError extends Error {
  constructor(message: string) {
    super(`[Internal Inconsistency] ${message}`)
    Object.setPrototypeOf(this, InternalInconsistencyError.prototype)
  }
}

const valueOrThrow = <V, E = Error>(result: Result<V, E>): V => {
  const { value, error } = result

  if (undefined === value) {
    throw error ||
      new InternalInconsistencyError("Result had no value or error")
  }

  return value
}

// const isSuccess = <T, E>(r: Result<T, E>): r is Success<T, E> => r.success;
const success = <T, E = unknown>(value: T): Result<T, E> => new Success(value)

// const isFailure = <T, E>(r: Result<T, E>): r is Failure<E, T> => !r.success;
const failure = <E, T = unknown>(error: E): Result<T, E> => new Failure(error)

export { Result, success, failure, valueOrThrow }
