import * as User from "./user"
import { Result } from "./result"

type EmailValidationResult = {
  status: boolean
  guest: boolean
  valid: boolean
}

type Interstitial = {
  loading: true
}

type Unauthenticated = {
  currentUser: null
  authenticateWithCredentials(
    email: string,
    password: string
  ): Promise<
    Result<unknown, { error: string; errors?: { [key: string]: string } }>
  >
  authenticateWithFacebook(
    accessToken: string
  ): Promise<
    Result<unknown, { error: string; errors?: { [key: string]: string } }>
  >
  registerNewUser(user: {
    email: string
    password: string
    name: string
    birthdate: Date
    subscribe: boolean
  }): Promise<Result<unknown, User.RegistrationErrors>>
  validateEmail(
    email: string
  ): Promise<Result<EmailValidationResult, undefined>>
  continueAsGuest(
    email: string
  ): Promise<
    Result<unknown, { error: string; errors?: { [key: string]: string } }>
  >
  resetPassword(email: string): Promise<Result<unknown, { error: string }>>
}

type Guest = {
  currentUser: User.Guest
  reset(): void
}

type Authenticated = {
  currentUser: User.Member
  signOut(): Promise<Result<any, any>>
}

type Any = Unauthenticated | Guest | Authenticated

const isInterstitial = (
  session: Any | Interstitial
): session is Interstitial => {
  return true === (session as Interstitial).loading
}

const isUnauthenticated = (
  session: Any | Interstitial
): session is Unauthenticated => {
  return !isInterstitial(session) && null === session.currentUser
}

const isGuest = (session: Any | Interstitial): session is Guest => {
  return (
    !isInterstitial(session) &&
    !isUnauthenticated(session) &&
    User.isGuest(session.currentUser)
  )
}

const isAuthenticated = (
  session: Any | Interstitial
): session is Authenticated => {
  return (
    !isInterstitial(session) &&
    !isUnauthenticated(session) &&
    User.isMember(session.currentUser)
  )
}

export {
  Any,
  Interstitial,
  Authenticated,
  Guest,
  Unauthenticated,
  isInterstitial,
  isAuthenticated,
  isGuest,
  isUnauthenticated,
  EmailValidationResult,
}
